import { Config } from '../types/common';

const commonConfig = {
  requestTimeout: 10000,
};

const config: Config = {
  development: {
    apiBaseUrl: process.env.REACT_APP_API_BASE_URL ?? "http://yenisey.local/api",
    environment: 'development',
    ...commonConfig,
  },
  production: {
    apiBaseUrl: process.env.REACT_APP_API_BASE_URL ?? "https://yenisey-api.dev.ssdemo.ru/api",
    environment: 'production',
    ...commonConfig,
  },
};

const environment = process.env.NODE_ENV || 'development';

export default config[environment];
