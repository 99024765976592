import React, {useEffect, useState} from 'react';
import {PlayerItem} from "../../../core/types/player";
import {useAppDispatch} from "../../../core/hooks";
import {fetchPosition} from "../../../core/store/api-actions";
import {simpleDateFormat} from "../../../core/utils/date-time-helpers";

function BirthDatesItem({player}: {player: PlayerItem}) {
  const dispatch = useAppDispatch();
  const [position, setPosition] = useState<string | null>(null);
  const birthDate = player?.birthday ? new Date(player.birthday) : null;

  useEffect(() => {
    const fetchPlayerPosition = async () => {
      const positionData = await dispatch(fetchPosition(Number(player.position_id))).unwrap();
      setPosition(positionData?.long_title);
    };

    fetchPlayerPosition();
  }, [player.position_id, dispatch]);

  return (
    <li className="birthdate__item">
      <img className="birthdate__img" src={player._links.photo.href} width="630" height="630" alt={`${player.first_name} ${player.last_name} ${player.middle_name}`}/>
      <div className="birthdate__content">
        <p className="birthdate__date" hidden={!birthDate}>{birthDate ? simpleDateFormat(birthDate, 'd MMMM') : ''}</p>
        <div className="birthdate__row">
          <p className="birthdate__number">{player.number}</p>
          <div className="birthdate__column">
            <p className="birthdate__name">{player.first_name} {player.last_name}</p>
            <p className="birthdate__role">{position}</p>
          </div>
        </div>
      </div>
    </li>
  );
}

export default BirthDatesItem;